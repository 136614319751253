<template>
  <div class="">
    <div class="form-row">
      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-6 col-md-4"
        label="รหัส"
        v-model="formData.code">
      </sgv-input-text>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-6 col-md-8"
        label="ชื่อ"
        v-model="formData.name"
        :validations="[
          {text: 'required!', value: v.formData.name.$dirty && v.formData.name.$error}
        ]">
      </sgv-input-text>

      <sgv-input-textarea
        :disabled="$auth.disabled(method)"
        class="col-12"
        label="รายละเอียด (พิมพ์)"
        :rows="3"
        v-model="formData.remark">
      </sgv-input-textarea>

      <DetailFormRepairInput
        label="ใบแจ้งซ่อม"
        class="col-12"
        select="id"
        :docId="docId"
        :docType="docType"
        :templateType="templateType"
        :method="method"
        :formData="formData">
      </DetailFormRepairInput>
    </div>

    <CategoryCheck
      :docId="docId"
      :docType="docType"
      :templateType="templateType"
      :disabled="$auth.disabled(method)"
      :method="method"
      :isClosed="!!formData.closedAt || !!formData.approvedAt"
      v-model="formData.categories"
      @updated="emitUpdated">
    </CategoryCheck>

    <div class="form-row">
      <sgv-input-text
        disabled
        class="col-4"
        label="สร้าง"
        :value="getState('created')">
      </sgv-input-text>

      <sgv-input-text
        disabled
        class="col-4"
        label="อนุมัติ"
        :value="getState('approved')">
      </sgv-input-text>

      <sgv-input-text
        disabled
        class="col-4"
        label="จบ"
        :value="getState('closed')">
      </sgv-input-text>
    </div>
  </div>
</template>

<script>
import DetailFormRepairInput from '@/views/doc_core/components/DetailFormRepairInput'
import CategoryCheck from '@/views/doc_core/components/CategoryCheck'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    },
    configs: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {

    }
  },
  methods: {
    getState (type) {
      const date = this.formData[type + 'At']
      const user = this.formData[type + 'User']
      if (!date || !user) return ''
      return this.$date.format(date).display + ' | ' + user.name
    },
    emitUpdated () {
      this.$emit('updated', null)
    }
  },
  components: {
    DetailFormRepairInput,
    CategoryCheck
  }
}
</script>

<style lang="css">
</style>
