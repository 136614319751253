<template>
  <div class="">
    <div class="mb-3 text-success">
      แบ่งสินค้า / รวมสินค้า ประเภทเดียวกัน
    </div>
    <div class="form-row">
      <DetailOrderItemInventoryInputInventory
        class="col-12"
        select="id"
        :docType="docType"
        :templateType="templateType"
        v-model="formData.inventoryId"
        :types="['product', 'material', 'store', 'factory', 'machine', 'instrument', 'mould']"
        :validations="[
          {text: 'required!', value: $v.formData.inventoryId.$dirty && $v.formData.inventoryId.$error}
        ]">
      </DetailOrderItemInventoryInputInventory>

      <sgv-input-text
        label="stockId"
        class="col-6"
        placeholder="ตัวอย่าง 1221 5421"
        v-model="formData.stockId"
        :validations="[
          {text: 'required!', value: $v.formData.stockId.$dirty && $v.formData.stockId.$error}
        ]">
      </sgv-input-text>

      <sgv-input-text
        label="จำนวน"
        class="col-6"
        placeholder="ตัวอย่าง. 10 5/2"
        v-model="formData.qty"
        @keyup.enter.native="createOrder"
        :validations="[
          {text: 'required!', value: $v.formData.qty.$dirty && $v.formData.qty.$error}
        ]">
      </sgv-input-text>
    </div>

    <div class="form-group">
      <button class="btn btn-success" @click="createOrder">
        เพิ่ม
      </button>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DetailOrderItemInventoryInputInventory from './DetailOrderItemInventoryInputInventory'
import { CREATE_ORDER_INVENTORY } from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      formData: {
        inventoryId: null,
        stockId: '',
        qty: ''
      }
    }
  },
  validations: {
    formData: {
      inventoryId: { required },
      stockId: { required },
      qty: { required },
    }
  },
  methods: {
    serializeInput (input) {
      const formData = {
        inventoryId: input.inventoryId,
        stockId: input.stockId.split(' ').map(v => +v),
        qty: input.qty.split(' ')
      }
      return formData
    },
    createOrder () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      this.$apollo.mutate({
        mutation: CREATE_ORDER_INVENTORY(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          input: this.serializeInput(this.formData)
        }
      })
      .then(res => {
        this.$emit('orderCreated', res.data.createOrder)
        this.setDefault()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    setDefault () {
      this.formData.inventoryId = null
      this.formData.stockId = ''
      this.formData.qty = ''
      this.$v.formData.$reset()
    }
  },
  watch: {
    'formData.inventoryId': {
      handler (v) {
        if (!v) {
          this.formData.stockId = ''
          this.formData.qty = ''
          this.$v.formData.$reset()
        }
      }
    }
  },
  created () {
    this.setDefault()
  },
  components: {
    DetailOrderItemInventoryInputInventory
  }
}
</script>

<style lang="css" scoped>
</style>
