<template>
  <div class="">
    <div class="mb-3 text-warning">
      แบ่งสินค้า / รวมสินค้า คนละประเภท
    </div>

    <div class="form-row">
      <DetailOrderItemTransformInputTransform
        label="ตารางส่วนผสม"
        ref="dropdownInventory"
        placeholder="เลือกรายการ"
        class="col-9"
        select="id"
        :docType="docType"
        :templateType="templateType"
        @change="updateFormData">
      </DetailOrderItemTransformInputTransform>

      <sgv-input-number
        label="จำนวนชุด"
        class="col-3"
        :precision="2"
        v-model="qty">
      </sgv-input-number>
    </div>

    <div class="row">
      <div class="col-12 col-sm-6">
        <div class="mb-2 text-danger" v-if="sourceItems.length > 0">
          รายการสินค้าที่ต้องใช้:
        </div>

        <div class="form-row">
          <sgv-input-text
            placeholder="stockId ตัวอย่าง 1234 445 "
            class="col-12"
            v-for="(form, index) in sourceItems"
            :key="form.docConfigId"
            :label="getSourceItemLabel(form)"
            v-model="form.stockId"
            :validations="[
              {text: 'required!', value: $v.sourceItems.$each[index].stockId.$dirty && $v.sourceItems.$each[index].stockId.$error}
            ]">
          </sgv-input-text>
        </div>
      </div>

      <div class="col-12 col-sm-6">
        <div class="mb-2 text-danger" v-if="sourceExpenses.length > 0">
          รายการต้นทุนสินค้าที่ต้องใช้:
        </div>

        <div class="form-row">
          <sgv-input-currency
            placeholder=""
            class="col-12"
            v-for="(form, index) in sourceExpenses"
            :key="form.docConfigId"
            :label="getSourceExpenseLabel(form)"
            v-model="form.price"
            :validations="[
              {text: 'required!', value: $v.sourceExpenses.$each[index].price.$dirty && $v.sourceExpenses.$each[index].price.$error}
            ]">
          </sgv-input-currency>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-if="transferItems.length > 0" class="col-12 col-sm-6">
        <div class="text-success mb-2">
          รายการสินค้าที่จะได้:
        </div>
        <ul>
          <li
            v-for="item in transferItems"
            :key="item.id">
            {{getTransferItemLabel(item)}}
          </li>
        </ul>
      </div>

      <div v-if="transferExpenses.length > 0" class="col-12 col-sm-6">
        <div class="text-success mb-2">
          รายการค่าใช้จ่ายที่จะได้:
        </div>
        <ul>
          <li
            v-for="item in transferExpenses"
            :key="item.id">
            {{getTransferExpenseLabel(item)}}
          </li>
        </ul>
      </div>
    </div>

    <div class="form-group">
      <button class="btn btn-warning" @click="createOrder">
        เพิ่ม
      </button>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import DetailOrderItemTransformInputTransform from './DetailOrderItemTransformInputTransform.vue'
import round from 'lodash/round'
import { CREATE_ORDER_TRANSFORM } from './graph'

export default {
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      formType: 'item',
      sourceItems: [],
      sourceExpenses: [],
      transferItems: [],
      transferExpenses: [],
      qty: 1,
      selectedDocConfigId: null
    }
  },
  validations () {
    const sourceItems = {
      $each: {
        stockId: { required }
      }
    }
    const sourceExpenses = {
      $each: {
        price: { required }
      }
    }
    return {sourceItems, sourceExpenses}
  },
  methods: {
    getSourceItemLabel (form) {
      const name = `${form.inventory.code} (${form.inventory.name})`
      const qty = `${round(form.sourceQty * this.qty, 2)} ${form.inventory.unit.name}`
      return `${name}: ${qty}`
    },
    getSourceExpenseLabel (form) {
      if (form.account) {
        return `${form.account.code} (${form.account.name})`
      }

      return form.junctionChildren[0].name
    },
    getTransferItemLabel (v) {
      const name = `${v.inventory.code} (${v.inventory.name})`
      const qty = `${round(v.qty * this.qty, 2)} ${v.inventory.unit.name}`
      return `${name}: ${qty}`
    },
    getTransferExpenseLabel (form) {
      if (form.account) {
        return `${form.account.code} (${form.account.name})`
      }

      return form.junctionChildren[0].name
    },
    updateFormData (docConfig) {
      if (!docConfig || docConfig.type !== this.formType) {
        this.sourceItems = []
        this.sourceExpenses = []
        this.transferItems = []
        this.transferExpenses = []
        this.selectedDocConfigId = null
        return
      }

      this.selectedDocConfigId = docConfig.id

      this.transferItems = docConfig.children.filter(v => v.type === 'transferItem')
      this.transferExpenses = docConfig.children.filter(v => v.type === 'transferExpense')

      this.sourceItems = docConfig.children
      .filter(v => v.type === 'sourceItem')
      .map(v => {
        return {
          inventory: v.inventory,
          sourceQty: +v.qty,
          docConfigId: v.id,
          stockId: ''
        }
      })
      this.sourceExpenses = docConfig.children
      .filter(v => v.type === 'sourceExpense')
      .map(v => {
        return {
          account: v.account,
          junctionChildren: v.junctionChildren,
          docConfigId: v.id,
          price: 0
        }
      })
    },
    serializeInput (sourceItems, sourceExpenses) {
      const formData = {
        docConfigId: this.selectedDocConfigId,
        qty: this.qty,
        items: sourceItems.map(v => {
          return {
            docConfigId: v.docConfigId,
            stockId: v.stockId.split(' ').map(v => +v)
          }
        }),
        expenses: sourceExpenses.map(v => {
          return {
            docConfigId: v.docConfigId,
            price: v.price
          }
        })
      }
      return formData
    },
    createOrder () {
      this.$v.sourceItems.$touch()
      this.$v.sourceExpenses.$touch()
      if (this.$v.sourceItems.$invalid) return
      if (this.$v.sourceExpenses.$invalid) return


      this.$apollo.mutate({
        mutation: CREATE_ORDER_TRANSFORM(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          input: this.serializeInput(this.sourceItems, this.sourceExpenses)
        }
      })
      .then(res => {
        this.$emit('orderCreated', res.data.createOrder)
        this.setDefault()
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$refs.dropdownInventory.resetForm()
      })
      .catch(this.$toasted.global.error)
    },
    setDefault () {
      this.sourceItems.docConfigId = null
      this.sourceItems.qty = ''
      this.$v.sourceItems.$reset()
      this.$v.sourceExpenses.$reset()
    }
  },
  watch: {
    'sourceItems.docConfigId': {
      handler (v) {
        if (!v) {
          this.sourceItems.qty = ''
          this.$v.sourceItems.$reset()
        }
      }
    }
  },
  created () {
    this.setDefault()
  },
  components: {
    DetailOrderItemTransformInputTransform
  }
}
</script>

<style lang="css" scoped>
</style>
