<template>
  <div>
    <div class="dashhead">
      <div class="dashhead-titles">
        <h6 class="dashhead-subtitle">{{subTitle}}</h6>
        <h3 class="dashhead-title">{{title}}</h3>
      </div>
    </div>

    <hr class="my-3">
    <div v-if="isLoaded">
      <sgv-form
        :method.sync="method"
        :options="options">

        <DetailForm
          :docId="docId"
          :docType="docType"
          :templateType="templateType"
          :formData="formData"
          :method="method"
          :configs="docConfigs"
          :v="$v"
          @updated="dataDetail">
        </DetailForm>

        <div class="form-row" slot="close">
          <sgv-input-date
            label="วันที่บันทึก"
            v-model="closedDate"
            class="col-12 col-md-4"
            :validations="[
              {text: 'required!', value: $v.closedDate.$dirty && $v.closedDate.$error}
            ]">
          </sgv-input-date>
        </div>
      </sgv-form>

      <DetailPrint
        ref="print"
        title="พิมพ์"
        :docId="docId"
        :docType="docType"
        :templateType="templateType">
      </DetailPrint>

      <template v-if="docId > 0 && formData.contactId">
        <HrDivider :options="tabs" v-model="selectedTab"></HrDivider>

        <template v-for="tab in tabs">
          <component
            :docId="docId"
            :docType="docType"
            :templateType="templateType"
            :key="tab.value"
            :is="tab.value"
            v-if="tab.value === selectedTab && docId > 0"
            :method="method"
            :formData="formData"
            :configs="docConfigs">
          </component>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import { required } from 'vuelidate/lib/validators'
import DetailForm from './DetailForm'
import DetailOrder from './DetailOrder'
import Attachment from '@/views/doc_core/components/Attachment'
import Ledger from '@/views/doc_core/components/Ledger'
import DetailPrint from './DetailPrint'
import {
  DETAIL_DOC, WATCH_DOC_UPDATED,
  CREATE_DOC, UPDATE_DOC, DESTROY_DOC,
  APPROVE_DOC, CANCEL_DOC,
  CLOSE_DOC, ROLLBACK_DOC,
} from './graph'

export default {
  mixins: [retainMixin],
  metaInfo () {
    return {
      title: `${this.formData.code}` || `${this.title}`
    }
  },
  props: {
    docId: {
      type: Number,
      required: true
    },
    docType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: true
    },
    group: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      method: 'info',
      listView: `Doc${this.$form.capitalize(this.docType)}List`,
      formData: {
        type: '',
        code: '',
        name: '',
        remark: '',
        contactId: null,
        createdAt: null,
        createdUser: null,
        approvedAt: null,
        approvedUser: null,
        closedAt: null,
        closedUser: null,
        repairs: [],
        categories: []
      },
      selectedTab: 'DetailOrder',
      docConfigs: [],
      isLoaded: false,
      closedDate: this.$date.current().date,
    }
  },
  validations: {
    formData: {
      name: { required }
    },
    closedDate: { required }
  },
  apollo: {
    $subscribe: {
      docUpdated: {
        query () {
          return WATCH_DOC_UPDATED(this.templateType)
        },
        variables () {
          return {
            docType: this.docType,
            docId: this.docId
          }
        },
        result (res) {
          this.setFormData(res.data.docUpdated)
        }
      }
    },
  },
  computed: {
    tabs () {
      let arr = [
        {text: 'รายการ', value: 'DetailOrder'},
        {text: 'ไฟล์', value: 'Attachment'},
        {text: 'บัญชี', value: 'Ledger'},
      ]
      return arr
    },
    options () {
      return [
        {
          text: 'เพิ่ม',
          variant: 'success',
          method: 'add',
          func: this.createData,
          disabled: true
        },
        {
          text: 'แก้ไข',
          variant: 'warning',
          method: 'edit',
          func: this.updateData,
          disabled: this.method === 'add' || !this.$auth.hasRole(`doc:${this.docType}:add`)
        },
        {
          text: 'ลบ',
          variant: 'danger',
          method: 'delete',
          func: this.destroyData,
          disabled: this.method === 'add' || this.formData.approvedAt || this.formData.closedAt || !this.$auth.hasRole(`doc:${this.docType}:add`)
        },
        {
          text: 'อนุมัติ',
          variant: 'warning',
          method: 'approve',
          func: this.approveData,
          disabled: this.method === 'add' || this.formData.approvedAt || !this.$auth.hasRole(`doc:${this.docType}:approve`)
        },
        {
          text: 'ยกเลิก',
          variant: 'info',
          method: 'cancel',
          func: this.cancelData,
          disabled: this.method === 'add' || !(this.formData.approvedAt && !this.formData.closedAt) || !this.$auth.hasRole(`doc:${this.docType}:approve`)
        },
        {
          text: 'จบ',
          variant: 'success',
          method: 'close',
          func: this.closeData,
          disabled: this.method === 'add' || !(this.formData.approvedAt && !this.formData.closedAt) || !this.$auth.hasRole(`doc:${this.docType}:close`)
        },
        {
          text: 'ย้อนกลับ',
          variant: 'info',
          method: 'rollback',
          func: this.rollbackData,
          disabled: this.method === 'add' || !(this.formData.approvedAt && this.formData.closedAt) || !this.$auth.hasRole(`doc:${this.docType}:close`)
        },
        {
          text: 'พิมพ์',
          variant: 'info',
          method: 'print',
          header: true,
          func: this.togglePrint,
          disabled: this.method === 'add'
        },
        {
          text: 'กลับ',
          variant: 'primary',
          func: this.closeForm,
          header: true,
          align: 'right'
        },
      ]
    }
  },
  methods: {
    togglePrint () {
      this.$refs.print.toggle()
    },
    dataDetail () {
      this.$apollo.query({
        query: DETAIL_DOC(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {this.setFormData(res.data.doc)})
      .catch(this.$toasted.global.error)
      .finally(() => {this.isLoaded = true})
    },
    setFormData (v) {
      Object.keys(this.formData).forEach(key => {
        if (key === 'categories') {
          this.formData[key] = v[key].map(x => x.id)
          return
        } else {
          this.formData[key] = v[key]
        }
      })
    },
    serializeInput (v) {
      const input = {
        ...v,
        type: this.type
      }
      const removeKeys = [
        'contactId', 'type', 'createdAt', 'approvedAt', 'closedAt',
        'createdUser', 'approvedUser', 'closedUser', 'repairs'
      ]
      removeKeys.forEach(x => delete input[x])
      return input
    },
    createData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: CREATE_DOC(this.templateType),
        variables: {
          docType: this.docType,
          input
        }
      })
      .then(res => {
        this.method = 'info'
        this.$toasted.global.success("เพิ่มสำเร็จ")
        this.$router.push({
          name: this.$route.name,
          params: {...this.$route.params, docId: res.data.doc.id},
          query: {...this.$route.query, redirect: this.listView}
        })
      })
      .catch(this.$toasted.global.error)
    },
    updateData () {
      this.$v.formData.$touch()
      if (this.$v.formData.$invalid) return

      const input = this.serializeInput(this.formData)
      this.$apollo.mutate({
        mutation: UPDATE_DOC(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          input
        }
      })
      .then(() => {
        this.method = 'info'
        this.$toasted.global.success("แก้ไขสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    destroyData () {
      this.$apollo.mutate({
        mutation: DESTROY_DOC(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        this.closeForm()
      })
      .catch(this.$toasted.global.error)
    },
    approveData () {
      this.$apollo.mutate({
        mutation: APPROVE_DOC(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
        }
      })
      .then(() => {
        this.method = 'info'
        this.$toasted.global.success("อนุมัติสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    cancelData () {
      this.$apollo.mutate({
        mutation: CANCEL_DOC(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
        }
      })
      .then(() => {
        this.method = 'info'
        this.$toasted.global.success("ยกเลิกสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    closeData () {
      this.$v.closedDate.$touch()
      if (this.$v.closedDate.$invalid) return

      this.$apollo.mutate({
        mutation: CLOSE_DOC(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
          closedDate: this.closedDate
        }
      })
      .then(() => {
        this.method = 'info'
        this.$toasted.global.success("จบรายการสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    rollbackData () {
      this.$apollo.mutate({
        mutation: ROLLBACK_DOC(this.templateType),
        variables: {
          docType: this.docType,
          docId: this.docId,
        }
      })
      .then(() => {
        this.method = 'info'
        this.$toasted.global.success("ย้อนรายการสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    closeForm () {
      if (this.$route.query.redirect) {
        if (this.$route.query.redirect === this.$route.name) {
          this.$router.push({name: this.listView})
        } else {
          this.$router.push({name: this.$route.query.redirect})
        }
      } else {
        this.$router.push({name: this.listView})
      }
    }
  },
  created () {
    if (this.docId === 0) {
      this.method = 'add'
      this.isLoaded = true
    } else {
      this.dataDetail()
    }
    this.$store.commit('path/setCurrent', {to: this.$route, group: this.group})
  },
  components: {
    DetailForm,
    DetailOrder,
    Attachment,
    DetailPrint,
    Ledger
  }
}
</script>

<style lang="css">
</style>
